.iconBackground {
  position: relative;
  display: flex;
  width: 60%;
  max-width: 32px;
  min-width: 16px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
}

.tooltipContent {
  text-align: center;
}
