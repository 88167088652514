@mixin scrubberTextNumberStyles() {
  color: var(--secondary-color-5);
  letter-spacing: var(--letter-spacing-loose);
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
}

:root {
  --scrubber-time-width: 80px;
  --scrubber-time-padding: 10px;
}

.scrubberContainer {
  display: grid;
  grid-gap: 2px;
  grid-template-areas:
    'nowPlayingSlider nowPlayingSlider'
    'nowPlayingElapsed nowPlayingDuration';
  width: 100%;
}

.scrubberWrapper {
  width: 100%;
}

.liveSeekScrubberContainer {
  composes: scrubberContainer;
  grid-template-areas:
    'nowPlayingSlider nowPlayingSlider'
    'nowPlayingElapsed nowPlayingGoLiveButton';
}

.slider {
  display: inline-block;
  width: calc(100% - var(--scrubber-time-width) * 2);
  color: var(--secondary-color-5);
  flex-grow: 1;
}

.nowPlayingSliderText {
  color: white;
}

.nowPlayingSlider {
  justify-self: center;
  grid-column: 1 / 3;
  color: var(--secondary-color-5);
  width: 100%;
}

/* begin MUI hackery */
.slider > div > div {
  height: 2px !important;
}

.slider > div > div div:nth-child(1),
.slider > div > div div:nth-child(2) {
  border-radius: 3px !important;

  :global(html.discord) & {
    background-color: var(--grey-7) !important;
  }
}

.slider > div > div div:nth-child(1),
.slider > div > div div:nth-child(3) {
  :global(html.discord) & {
    background-color: var(--anti-flash-white) !important;
    border: none !important;
    margin-top: 1px;
  }
}

.slider.disabled > div > div div:nth-child(2) {
  width: calc(100% - 8px) !important;
}

.slider.disabled > div > div div:nth-child(3) {
  left: 4px !important;
  background-color: #000 !important;
}

.nowPlayingSlider > div > div {
  :global(html.discord) & {
    height: 2px !important;
  }
}

.nowPlayingSlider > div > div > div:nth-child(1),
.nowPlayingSlider > div > div > div:nth-child(3) {
  background-color: white !important;
  border: none !important;

  .isLive & {
    display: none;
  }
}

.nowPlayingSlider.isLive > div > div > div:nth-child(3) {
  display: none;
}

.nowPlayingSlider > div > div > div:nth-child(2) {
  background-color: var(--secondary-color-7) !important;

  :global(html.discord) & {
    background-color: var(--medium-grey) !important;
  }
}

.slider.isLive {
  :global(html.discord) & {
    display: none;
  }
}

.slider.isLive > div::after {
  content: 'LIVE';
  z-index: 11;
  font-weight: bold;
  font-size: 8px;
  line-height: 11px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 50px;
  color: var(--content-area-background-color-hex);
  background-color: var(--51st-shade-of-grey);
  box-shadow: -3px 0 60px 70px var(--content-area-background-color-hex);
  border-radius: 50px;
  padding: 2px 9px;

  :global(html.discord) & {
    color: var(--anti-flash-white);
    background-color: transparent;
    box-shadow: none;
  }
}

.slider.isLive > div > div > div:nth-child(2) {
  height: 50%;
}

.slider.isLive > div > div > div:nth-child(3) {
  display: none;
}

/* end MUI hackery */

.time {
  display: inline-block;
  vertical-align: top;
  color: var(--secondary-color-5);
  width: var(--scrubber-time-width);
  font-size: 11px;
  line-height: 1.45;
  user-select: none;

  :global(html.discord) & {
    color: var(--anti-flash-white);
  }
}

.elapsed {
  composes: time;
  text-align: right;
  padding-right: var(--scrubber-time-padding);
  width: auto;

  :global(html.discord) & {
    width: var(--player-timestamp-width);
  }

  span {
    @include scrubberTextNumberStyles;

    :global(html.discord) & {
      color: var(--anti-flash-white);
    }
  }
}

.nowPlayingElapsed {
  composes: nowPlayingSliderText;
  grid-area: nowPlayingElapsed;
  justify-self: start;

  span {
    font-size: var(--small-label-font-size);
    font-weight: var(--font-weight-bold);
  }
}

.duration {
  composes: time;
  padding-left: var(--scrubber-time-padding);
  width: auto;

  @include scrubberTextNumberStyles;

  :global(html.discord) & {
    width: var(--player-timestamp-width);
  }
}

.nowPlayingDuration {
  composes: nowPlayingSliderText;
  grid-area: nowPlayingDuration;
  font-weight: var(--font-weight-bold);
  font-size: var(--small-label-font-size);
  justify-self: end;
}

.goLiveButton {
  margin-left: var(--scrubber-time-padding);
  border-radius: 12px;
  background-color: var(--content-area-background-color-hex);
  font-size: 11px;
  cursor: pointer;

  &:hover {
    background-color: var(--grey-2);
  }
}

.nowPlayingGoLiveButton {
  grid-area: nowPlayingGoLiveButton;
  border-radius: 12px;
  background-color: var(--grey-3);
  font-weight: var(--font-weight-bold);
  font-size: var(--small-label-font-size);
  justify-self: end;
  margin-right: 25px;
  color: white;
  padding-top: 4px !important;

  &:hover {
    background-color: var(--grey-2);
  }
}

.liveSeekTextContainer {
  composes: time;
  width: 60px;
  height: 22px;
  text-align: center;
  letter-spacing: var(--letter-spacing-loose);
  font-weight: bold;
  color: var(--secondary-color-5);
}

.nowPlayingLiveSeekTextContainer {
  width: 60px;
  height: 22px;
  text-align: center;
  font-weight: bold;
  font-weight: var(--font-weight-bold);
  color: var(--secondary-color-5);
}

.liveSeekText {
  font-size: 11px;
}

.nowPlayingLiveSeekText {
  font-size: 11px;
}
