/* TITLES */
.guideItemTitle {
  color: var(--text-color);
  transition: var(--base-transition);

  [data-darkmode='true'] & {
    color: var(--white);
  }
}

.guideItemTitleSingleLine {
  composes: guideItemTitle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.isDiscordPlayableSection {
    font-weight: bold;
  }
}

.guideItemTitleMultiLine {
  composes: guideItemTitle;
  white-space: normal;
  word-break: break-word;
}

.guideItemTitleAlt {
  display: block;
  color: var(--text-color);
  font-size: var(--font-size-2);
  line-height: 19px;

  [data-darkmode='true'] & {
    color: var(--white);
  }

  &:hover {
    color: var(--cloud-grey);
  }
}

.guideItemTitleSizeLarge {
  font-weight: bold;
  font-size: var(--font-size-6);
  line-height: 28px;

  @media (--min-width-small) {
    font-size: 32px;
    line-height: 36px;
  }
}

/* SUBTITLES */
.guideItemSubtitleMultiLine {
  padding-top: 4px;
  color: var(--sub-text-color);
  font-family: var(--mono-font);
  line-height: var(--list-item-sub-line-height);
  font-size: var(--font-size-1);

  [data-darkmode='true'] & {
    color: var(--white);
  }

  :global(html.discord) & {
    color: var(--sub-text-color);
  }
}

.guideItemSubtitle {
  composes: guideItemSubtitleMultiLine;

  &.isDiscordPlayableSection {
    color: var(--cloud-grey);
  }
}

.guideItemSubtitleSizeLarge {
  font-weight: bold;
  font-size: var(--font-size-3);
  line-height: 20px;

  @media (--min-width-small) {
    font-size: var(--font-size-4);
    line-height: 22px;
  }
}

.guideItemLink {
  composes: link from '../../../styles/common.module.scss';
  display: block;

  &:hover {
    .guideItemTitle {
      color: var(--cloud-grey);
    }
  }
}

/* TITLE/SUBTITLE ALIGNMENT */
.textAlignCenter {
  text-align: center;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.statusFontSection {
  padding-top: 12px;
  font-size: var(--font-size-1);
  line-height: var(--guide-sub-line-height);
}

.guideItemDescription {
  padding-top: 5px;
  color: var(--text-color);
  font-size: var(--font-size-2);
  line-height: 19px;

  [data-darkmode='true'] & {
    color: var(--white);
  }
}

.guideItemDurationContainer {
  span {
    padding-right: 5px;
  }
}

.newPlayableItemIndicator {
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 6px;
  margin: 1.8px 9px;
  background-color: var(--primary-color-1);
}

.newPlayableItemContainer {
  position: relative;
  height: 100%;
  width: 100%;

  .dot {
    position: absolute;
    left: 8px;
    top: 8px;
    height: 10px;
    width: 10px;
    border-radius: 10px;
    z-index: 2;
    background-color: var(--primary-color-1);

    &.dotRegular {
      @media (--min-width-medium) {
        left: 4px;
        top: 4px;
        height: 4px;
        width: 4px;
      }
    }
  }

  .dogEar {
    position: absolute;
    left: -46px;
    top: -46px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 40px;
    border-color: white;
    box-shadow: var(--zdepth1-box-shadow);
    transform: rotate(45deg);
    z-index: 1;

    &.dogEarRegular {
      @media (--min-width-medium) {
        top: -70px;
      }
    }
  }
}

.badgeImage {
  position: absolute;
  right: 8%;
  top: 0;
  width: 12%;
  z-index: 2;

  &.badgeImageRegular {
    @media (--min-width-medium) {
      right: 12%;
      width: 16%;
    }
  }
}

.guideItemContainer {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  padding: 8px;
  margin-left: -8px;

  &.noMarginTop {
    margin-top: 0;
  }
}

.guideItemTitleContainerLarge {
  padding-left: 20px;
  max-width: 400px;

  @media (--min-width-small) {
    padding-left: 25px;
    max-width: 500px;
  }
}

.premiumTile {
  .guideItemContainer {
    margin-bottom: 0;
  }
}

.guideItemTitlesContainer {
  composes: verticalAlignChild from '../../../styles/common.module.scss';
  display: inline-block;
  padding-left: 15px;
}

.guideItemTitlesContainerNoImage {
  padding-left: 0;
  position: relative;
  padding-top: 25px;
}

/* Guide Item Image Wrapper */
.guideItemImageWrapper {
  float: left;
  width: 66px;
  height: 66px;

  @media (--min-width-small) {
    width: 132px;
    height: 132px;
  }

  @media (--min-width-medium) {
    width: 64px;
    height: 64px;
  }

  @media (--min-width-xxlarge) {
    width: 90px;
    height: 90px;
  }

  :global(html.ford) & {
    width: 130px;
    height: 130px;
  }
}

.guideItemImageWrapperLarge {
  width: 120px;
  height: 120px;

  @media (--min-width-small) {
    width: 140px;
    height: 140px;
  }
}

.guideItemPlayButtonContainer {
  position: relative;
  margin-left: 0;
  overflow: hidden;
  width: 100%;
  background: rgb(0 0 0 / 0%);
  height: 100%;
}

.guideItemImageFill {
  position: absolute;
  top: 50%;
  max-width: 100%;
  transform: translateY(-50%);
}
