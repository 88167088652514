.popover {
  bottom: 108px;
  right: 75px;
  min-width: 120px;
  background-color: var(--content-area-background-color-hex);
  border-radius: 9px;

  :global(html.discord) & {
    bottom: var(--player-height);
    margin-bottom: 10px;
  }
}

.popover .content {
  padding: 12px 0;
}

.popover .row {
  color: var(--text-color);
  padding: 0 16px 0 20px;
  height: 24px;
  margin: 4px 0;
  cursor: pointer;

  :global(html.discord) & {
    svg {
      margin-bottom: 2px;
    }

    path {
      stroke: var(--anti-flash-white);
    }
  }
}

.speedControlText {
    color: var(--text-color);
  font-size: 17px;
  line-height: 17px;
  text-align: center;

  :global(html.discord) & {
    color: var(--anti-flash-white);

    @media (--min-width-xsmall) {
      font-size: var(--font-size-2);
    }

    @media (--min-width-small) {
      font-size: 17px;
    }
  }
}

.mobileSpeedControlText {
  color: var(--text-color);
  font-size: 17px;
}
