.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--tile-z-index-1);
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background: rgb(255 255 255 / 20%);
  opacity: 0;
  cursor: pointer;
  transition: var(--base-transition);

  :global(html.discord) & {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0 0 0 / 0%);
    gap: 6%;
  }

  &.mobile {
    display: none;
  }

  &.hide {
    opacity: 0;
  }

  &.show,
  &:hover {
    opacity: 1;

    .iconPosition {
      opacity: 1;
      transform: scale(1.1);

      :global(html.discord) & {
        transform: none;
      }
    }
  }
}

.hide::before {
  opacity: 0;
}

.show::before {
  opacity: 1;
}

.lock {
  color: white;
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 1vw;
}

.playButton {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 3px;

  &.dynamicHeight {
    height: auto;
  }
}

/*
 setting specific right and bottom values, as using 50% would require translate(),
 which would animated the positioning due to the transition rule above
*/
.iconPosition {
  position: absolute;
  right: 10%;
  bottom: 10%;
  z-index: 10;
  overflow: visible !important;
  opacity: 0;
  transition: var(--base-transition);
  transform: scale(0.9);

  :global(html.discord) & {
    position: static;
    height: var(--discord-tile-control-button-width);
    width: auto;
    transform: none;
  }

  &.regularTileSizeOffset {
    right: 20%;
    bottom: 19%;
  }

  &.circleTileShapeOffset {
    right: 30%;
    bottom: 29%;
  }
}

.progressWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
