.container {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  bottom: 0;
  right: 0;
  max-width: 50%;
  max-height: 50%;
  padding: 3px;
  opacity: 1;
  z-index: var(--tile-z-index-2);
  user-select: none;

  &:hover {
    opacity: 1;
  }
}
