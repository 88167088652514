$arrowBorderSize: 12px;
$popoverWidth: 210px;

.messagePopover {
  width: $popoverWidth;
  line-height: 16px;
  font-size: var(--font-size-1);
  box-shadow: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 0.5rem));

  :global(html.discord) & {
    color: black;
  }

  &::after {
    content: ' ';
    border-top: $arrowBorderSize solid var(--anti-flash-white);
    border-left: $arrowBorderSize solid transparent;
    border-right: $arrowBorderSize solid transparent;
    position: relative;
    top: calc($arrowBorderSize - 2px);
    left: calc(($popoverWidth - ($arrowBorderSize * 2)) / 2);
  }
}

.messagePopoverContent {
  text-align: center;
  background-color: var(--anti-flash-white) !important;
  padding: 10px;
}
