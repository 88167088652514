.value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-2);
  line-height: 19px;
  margin-right: auto;

  &:hover {
    background-color: var(--elephant-grey);

    :global(html.discord) & {
      background-color: var(--grey-7);
    }
  }
}
