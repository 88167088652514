.container {
  height: 100%;
}

.header {
  color: var(--text-color);
  font-weight: bold;
}

.row {
  display: flex;
  align-items: center;

  @media (hover: hover) {
    &:hover {
      background-color: var(--elephant-grey);

      :global(html.discord) & {
        background-color: var(--grey-7);
      }
    }
  }
}

.row p {
  color: var(--text-color);
  font-size: var(--font-size-1);
}

/* Dialog Layout */
.dialogBody {
  padding: 0 !important;

  :global(html.discord) & {
    background-color: var(--grey-7);
  }
}

.dialogContent {
  border-radius: 10px !important;

  > div {
    :global(html.discord) & {
      background-color: transparent !important;
    }
  }
}

.dialogContent .closeIcon {
  top: 26px;
  right: 22px;
  width: 12px;
  height: 12px;

  :global(html.discord) & {
    color: var(--text-color);
  }
}

.dialogContent .content {
  padding: 20px 0 24px;
}

.dialogContent .header,
.dialogContent .row {
  padding: 0 18px 0 24px;
}

.dialogContent .header {
  font-size: 22px;
  line-height: 1.13;
  margin-bottom: 16px;
}

.dialogContent .row {
  margin-top: 16px;
}

.dialogContent .row p {
  font-size: var(--font-size-5);
  line-height: 1.29;
  margin-right: auto;
}

.dialogContent .favoriteIcon {
  width: 20px;
  height: 20px;
}

/* Popover Layout */
.popover {
  bottom: 80px;
  right: 10px;
  min-width: 172px;

  :global(html.discord) & {
    bottom: var(--player-height);
    margin-bottom: 10px;
  }
}

.popover .content {
  padding: 12px 0;
}

.popover .header,
.popover .row {
  padding: 0 10px 0 16px;
}

.popover .header {
  font-size: var(--font-size-3);
  line-height: 1.13;
  margin-bottom: 11px;
}

.popover .row {
  height: 24px;
  margin-top: 7px;
  cursor: pointer;
}

.popover .row p {
  font-size: var(--font-size-2);
  line-height: 1.29;
  margin-right: auto;
}

.popover .favoriteIcon {
  width: var(--medium-icon-size);
  height: var(--medium-icon-size);
}
