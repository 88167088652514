.popover {
  bottom: var(--player-height);
  background-color: var(--content-area-background-color-hex);
  border-radius: 36px;
  margin-bottom: 10px;

  :global(html.discord) & {
    background-color: var(--medium-grey);
  }
}

.overflowVisible {
  overflow: visible !important;
}

.disabledVolume {
  opacity: 0.5;

  &:hover {
    cursor: default !important;
    background-color: #f6f8f6 !important;
  }
}

/* begin MUI hackery */
.volumeSlider {
  :global(html.discord) & {
    background-color: var(--medium-grey) !important;
  }
}

.volumeSlider > div > div {
  width: 2px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.volumeSlider > div > div div:nth-child(1) {
  :global(html.discord) & {
    background-color: var(--anti-flash-white) !important;
    border-radius: 50%;
  }
}

.volumeSlider > div > div div:nth-child(2) {
  :global(html.discord) & {
    background-color: var(--grey-7) !important;
  }
}

.volumeSlider > div > div div:nth-child(3) {
  margin: 0 0 0 1px !important;

  :global(html.discord) & {
    background-color: var(--anti-flash-white) !important;
  }
}

/* end MUI hackery */
