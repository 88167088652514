.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.favoriteHeart {
  width: var(--large-icon-size);
  height: var(--large-icon-size);
  stroke: var(--text-color)
}

.favoriteIconLabel {
  font-family: var(--primary-font) !important; // this is because this is a child on an [class*=icon-] with a different font
  font-size: var(--font-size-1);
  font-weight: var(--font-weight-bold);
  color: var(--text-color);
  line-height: normal;
  letter-spacing: var(--letter-spacing-loose);
  text-transform: uppercase;
}
