.selectableTile {
  position: relative;
}

.selectableTileOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: var(--secondary-color-5);
  z-index: 1;
  transition: opacity 150ms ease-in-out;

  &.selected {
    opacity: 0.7;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.5;
    }

    &:active {
      opacity: 0.7;
    }

    &.selected:active {
      opacity: 0;
    }
  }
}

.checkmark {
  position: absolute;
  bottom: 9px;
  right: 9px;
  width: 22px;
  height: 22px;
  background: transparent url('/assets/img/shared/circle-checkmark.svg')
    center/contain no-repeat;
  visibility: hidden;
  z-index: 2;

  &.visible {
    visibility: visible;
  }
}
