.arrow {
  width: 20px;
  height: 12px;
  fill: var(--anti-flash-white);
}

.content {
  background-color: var(--anti-flash-white);
  color: var(--input-bg-color);
  border-radius: 8px;
  padding: 0.5rem 1rem;
  z-index: var(--top-z-index);
}
