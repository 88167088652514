.solid {
  display: block;
  height: auto;

  .outer {
    transition: var(--base-transition);
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    &:hover .outer {
      fill: var(--cloud-grey);
    }
  }

  @media (hover: hover) {
    &:hover .outer {
      fill: var(--cloud-grey);
    }
  }

  @media (hover: none) {
    &:active .outer {
      fill: var(--cloud-grey);
    }
  }
}
