.playButtonWrapper {
  position: relative;

  :global(html.discord) & {
    &.isDisabled .playerPlayButton {
      opacity: var(--lighter-disabled-opacity-value);
    }
  }
}

.playerPlayButton {
  overflow: visible !important;
  width: 100%;

  :global(html.discord) & {
    &[data-icon='pause'] {
      path:nth-child(2) {
        fill: var(--anti-flash-white) !important;
      }

      rect {
        fill: var(--secondary-color-5) !important;
        width: 4px;
        height: 15px;
        transform: translateY(2px);
      }
    }

    &[data-icon='play'] {
      path:nth-child(2) {
        fill: var(--anti-flash-white) !important;
      }

      path:nth-child(3) {
        fill: var(--secondary-color-5) !important;
      }
    }
  }
}

.popover {
  &:after {
    left: unset;
  }
}
